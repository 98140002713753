<template>
  <div>
    <br />
    <div class="container">
      <el-alert
        v-if="alreadyResponse"
        :type="repliedStatus === ACCEPT ? 'success' : 'info'"
        show-icon
      >
        <template slot="title">
          <span v-if="repliedStatus === ACCEPT">
            You have agreed to this lesson’s request. Please wait for the administrator’s final decision.
          </span>
          <span v-if="repliedStatus === REJECT">
            You have rejected this class’s request.
          </span>
          <router-link
            :to="{
              name: 'privateLessonInvitationReply',
              params: {
                class_teacher_invitation_id: $route.params.class_teacher_invitation_id,
                reply_status: $route.params.reply_status === ACCEPT ? REJECT : ACCEPT
              },
              query: {
                session_class_id: $route.query.session_class_id
              }
            }"
          >
            Change your response
          </router-link>
        </template>
      </el-alert>
    </div>
    <TeacherAvailableDialog
      :visible="teacherAvailableDialogVisible"
      :classId="$route.query.session_class_id"
      @onSubmit="reply"
    />
  </div>
</template>

<script>
import arrangeApi from "@/apis/arrange";
import TeacherAvailableDialog from "@/views/private/TeacherAvailableDialog";

export default {
  metaInfo() {
    return {
      title: "Private Lesson Invitation - Ivy-Way Academy"
    };
  },

  components: {
    TeacherAvailableDialog
  },

  mixins: [],

  props: [],
  data() {
    return {
      repliedStatus: 0,
      teacherAvailableDialogVisible: false,
      alreadyResponse: false
    };
  },
  computed: {
    ACCEPT() {
      return "1";
    },
    REJECT() {
      return "2";
    }
  },
  watch: {},
  mounted() {
    switch (this.$route.params.reply_status) {
      case this.ACCEPT:
        this.teacherAvailableDialogVisible = true;
        break;
      case this.REJECT:
        this.reply();
        break;
      default:
    }
  },
  methods: {
    async reply(repliedNote) {
      this.repliedStatus = this.$route.params.reply_status;
      let param = {
        class_teacher_invitation_id: this.$route.params
          .class_teacher_invitation_id,
        replied_status: this.$route.params.reply_status
      };

      if (this.repliedStatus === this.ACCEPT) {
        param.replied_note = repliedNote;
      }

      await arrangeApi.replyTeacherInvitation(param);
      this.teacherAvailableDialogVisible = false;
      this.alreadyResponse = true;
    }
  }
};
</script>
