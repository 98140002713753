<template>
  <el-dialog
    class="dialog"
    :visible.sync="visible"
    :show-close="false"
    :close-on-click-modal="false"
    :close-on-press-escape="false"
  >
    <b>Student's availability:</b>
    <div v-if="studentAvailable">
      {{ studentAvailable }}
    </div>
    <hr />
    <el-form header-align="left">
      <el-form-item
        require
        label="Based on the student's availability, please confirm a time that you can meet (if those times don't work for you, please list your availability over the next few days)"
      >
        <el-input type="textarea" rows="5" v-model="repliedNote" />
      </el-form-item>
    </el-form>
    <span slot="footer" class="dialog-footer">
      <el-button @click="onClose" plain>
        {{ $t("button.cancel") }}
      </el-button>
      <el-button type="primary" @click="onSubmit">
        {{ $t("button.save") }}
      </el-button>
    </span>
  </el-dialog>
</template>

<script>
import courseApi from "@/apis/course";

export default {
  props: {
    visible: {
      default: false,
      type: Boolean
    },
    classId: {
      default: null,
      type: [String, Number]
    }
  },
  watch: {
    classId() {
      this.fetchStudentAvailable();
    }
  },
  data() {
    return {
      repliedNote: "",
      studentAvailable: ""
    };
  },
  created() {
    this.fetchStudentAvailable();
  },
  methods: {
    async fetchStudentAvailable() {
      if (this.classId) {
        const {
          session_class: { request_time_info }
        } = await courseApi.fetchSessionClass(this.classId);
        this.studentAvailable = request_time_info;
      }
    },
    onSubmit() {
      if (this.repliedNote.trim() === "") {
        this.$message.error("Please confirm a time that you can meet.");
        return;
      }
      this.$emit("onSubmit", this.repliedNote);
      this.repliedNote = "";
    },
    onClose() {
      this.$emit("onClose");
    }
  }
};
</script>

<style scoped>
::v-deep .el-form-item__label {
  line-height: 26px;
  text-align: left;
}

@media (max-width: 992px) {
  ::v-deep .el-dialog {
    width: 90%;
  }
}

</style>
